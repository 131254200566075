exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-contentful-post-slug-js": () => import("./../../../src/pages/blog/{ContentfulPost.slug}.js" /* webpackChunkName: "component---src-pages-blog-contentful-post-slug-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-contentful-project-slug-js": () => import("./../../../src/pages/projects/{ContentfulProject.slug}.js" /* webpackChunkName: "component---src-pages-projects-contentful-project-slug-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-what-is-staff-augmentation-js": () => import("./../../../src/pages/what-is-staff-augmentation.js" /* webpackChunkName: "component---src-pages-what-is-staff-augmentation-js" */)
}

